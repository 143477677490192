import React from "react";

export default function ActivityReleaseIcon({
    size = 16,
    color = '#7498CF'
}: {
    readonly size?: number,
    readonly color?: string
}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
            <path d="M5.81563 3.8024C5.56698 3.8024 5.32853 3.89722 5.15271 4.066C4.9769 4.23479 4.87813 4.46371 4.87813 4.7024C4.87813 4.9411 4.9769 5.17001 5.15271 5.3388C5.32853 5.50758 5.56698 5.6024 5.81563 5.6024H10.1906C10.4393 5.6024 10.6777 5.50758 10.8535 5.3388C11.0294 5.17001 11.1281 4.9411 11.1281 4.7024C11.1281 4.46371 11.0294 4.23479 10.8535 4.066C10.6777 3.89722 10.4393 3.8024 10.1906 3.8024H5.81563ZM12.9969 3.2576C12.9725 2.91633 12.8141 2.59667 12.5536 2.36322C12.2931 2.12978 11.9501 1.99996 11.5938 2H4.40625L4.31 2.003C3.95451 2.02641 3.62153 2.17851 3.37836 2.42855C3.13518 2.67859 2.99996 3.00792 3 3.35V12.65L3.00313 12.7424C3.02751 13.0837 3.18595 13.4033 3.4464 13.6368C3.70686 13.8702 4.04992 14 4.40625 14H11.5938L11.69 13.997C12.0455 13.9736 12.3785 13.8215 12.6216 13.5715C12.8648 13.3214 13 12.9921 13 12.65V3.35L12.9969 3.2576ZM4.40625 2.9H11.5938L11.6575 2.9042C11.7697 2.91899 11.8726 2.97229 11.947 3.05424C12.0215 3.13619 12.0625 3.24126 12.0625 3.35V12.65L12.0581 12.7112C12.0427 12.8189 11.9872 12.9177 11.9018 12.9891C11.8165 13.0606 11.707 13.1 11.5938 13.1H4.40625L4.3425 13.0958C4.23028 13.081 4.12743 13.0277 4.05298 12.9458C3.97853 12.8638 3.9375 12.7587 3.9375 12.65V3.35L3.94187 3.2888C3.95728 3.18107 4.0128 3.08233 4.09817 3.01086C4.18354 2.93939 4.29298 2.9 4.40625 2.9Z" fill={color}/>
        </svg>
    )
}