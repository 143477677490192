import Button from 'app/components/Button'
import Input from 'app/components/Input'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectProfileForm } from 'app/containers/OnBoarding/redux/selector';
import { actions } from '../../redux/slice';
import PasswordIcon from 'app/components/Icons/PasswordIcon';
import UserIcon from 'app/components/Icons/UserIcon';
import { validateProfileForm } from '../../helpers';
import { useNavigate } from 'react-router-dom';
import Logo from 'app/components/Icons/Logo';
import { Select } from 'app/components/Select';
import { LocationIcon } from 'app/components/Icons/LocationIcon';
import { TimeZones } from '../../data';


export default function CreateProfile() {
    const dispatch = useDispatch();
    const { name, password, confirmPassword, timezone, isLoading } = useSelector(selectProfileForm);
    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(actions.setProfileFormValue({ 
            name: e.target.name, 
            value: e.target.value 
        }));
    }

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const data = {
            name: {
                value: name.value,
                error: name.error,
            },
            password: {
                value: password.value,
                error: password.error,
            },
            confirmPassword: {
                value: confirmPassword.value,
                error: confirmPassword.error,
            },
            timezone: {
                value: timezone.value,
                error: timezone.error,
            },
        }
        const errors = validateProfileForm(data);
        if (errors.length > 0) {
            dispatch(actions.setProfileFormErrors(errors));
            return;
        }
        dispatch(actions.saveProfileRequest({ 
            callback: () => navigate("/onboarding/welcome")
        }));
    }

    return (
        <div className="flex flex-col items-center">
            <div className='flex flex-col items-center gap-[20px] mb-[56px]'>
                <Logo size={72}  />
                <h1 className="text-[40px] leading-[50px] font-semibold mb-4 text-center">Create a profile</h1>
            </div>
            <form className='flex flex-col gap-[20px] w-full min-w-[390px] mb-[56px]'>
                <Input 
                    id="email" 
                    label="Full Name" 
                    placeholder="Enter your name" 
                    icon={<UserIcon color="#BDC0C4" size={20} />}
                    value={name.value}
                    onChange={handleChange}
                    error={name.error}
                    name='name'
                    disabled={isLoading}
                />
                <Select 
                    options={TimeZones}
                    value={timezone.value}
                    onChange={(v)=>{
                        dispatch(actions.setProfileFormValue({
                            name: "timezone",
                            value: v,
                        }));
                    }}
                    label="Country/Timezone"
                    placeholder="Choose country/timezone"
                    icon={<LocationIcon color="#BDC0C4" size={20} />}
                    menuListItemLabelClassName="text-xs !leading-[21px] font-normal !text-[#79808A]"
                    menuListItemClassName="min-h-[50px] justify-center"
                    searchPlaceholder="Search by country/timezone name"
                    allowSearch
                    error={timezone.error}
                />
                <Input 
                    id="password" 
                    label="Password" 
                    type="password" 
                    placeholder="Password" 
                    icon={<PasswordIcon color="#BDC0C4" size="24" />}
                    value={password.value}
                    onChange={handleChange}
                    error={password.error}
                    name='password'
                    disabled={isLoading}
                />
                <Input 
                    id="confirmPassword" 
                    label="Confirm Password" 
                    type="password" 
                    placeholder="Confirm Password" 
                    icon={<PasswordIcon color="#BDC0C4" size="24" />}
                    value={confirmPassword.value}
                    onChange={handleChange}
                    error={confirmPassword.error}
                    name='confirmPassword'
                    disabled={isLoading}
                />
                <Button
                    className='w-full h-[56px]'
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    disabled={isLoading}
                >
                    Create profile
                </Button>
            </form>
        </div>
    )
}
