import React from 'react';
import Filters from '../components/Filters';
import Cards from '../components/Cards';
import Input from 'app/components/Input';
import Button from 'app/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { PLAN_TYPE } from 'app/types';
import { selectTeamForm } from 'app/redux/selector';
import { actions } from 'app/redux/slice';

interface Props {
    onProceed: (plan: PLAN_TYPE) => void;
}

const Pricing = ({
    onProceed,
}: Props) => {
    const { coupon } = useSelector(selectTeamForm);
    const dispatch = useDispatch();

    const setTeamFormValue = (payload: { name: string, value: string }) => {
        dispatch(actions.setTeamCoupon(payload));
    }
    return (
        <div className="flex flex-col gap-[16px] mb-[56px] overflow-auto">
            <Filters />
            <Cards onClick={onProceed} />
            <div className="flex justify-center items-end gap-[10px] flex-row">
                <Input
                    id="discount"
                    label='Code Redemption'
                    placeholder="Enter redemption code"
                    inputClassName="w-[400px] h-[48px]"
                    labelClassName="text-black leading-[21px] text-sm text-center font-medium"
                    value={coupon.value}
                    onChange={(e) => setTeamFormValue({ name: 'coupon', value: e.target.value })}
                    error={coupon.error}
                />
                <Button disabled={!!coupon.error || !coupon.value} className="h-[48px] px-[20px]">
                    Apply code
                </Button>
            </div>
        </div>
    );
}

export default Pricing;
