import React from 'react'

export default function OtherAgileIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
            <path d="M14.5846 15.8268H18.3346M18.3346 15.8268L16.2513 13.7435M18.3346 15.8268L16.2513 17.9102M10.0013 1.66016L7.91797 3.74349L10.0013 5.82682" stroke="#6BD60E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.7513 3.74316C10.4089 3.74316 11.9986 4.40164 13.1707 5.57375C14.3428 6.74585 15.0013 8.33556 15.0013 9.99316C15.0013 11.6508 14.3428 13.2405 13.1707 14.4126C11.9986 15.5847 10.4089 16.2432 8.7513 16.2432H1.66797" stroke="#6BD60E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.63 4.57715C4.67836 5.12541 3.88799 5.91475 3.3385 6.86568C2.78901 7.81661 2.4998 8.89554 2.5 9.99381C2.5 11.4005 2.965 12.6988 3.75 13.7438" stroke="#6BD60E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}
