import React from 'react'
import StatusIcon from 'app/components/Icons/StatusIcon'
import menuIcon from "assets/svgs/ic_menu-grey.svg"
import Card from 'app/components/Card'
import DropdownIcon from 'app/components/Icons/DropdownIcon'
import addIcon from "assets/svgs/ic_plus_primary.svg"
import { TicketGroup } from '../../../types'
import TicketCard from './Ticket'
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import { DropIndicator } from 'app/components/DropIndicator'
import { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types'

interface ColumnProps {
    group: TicketGroup;
    onDragEnd?: (ticketId: string, sourceStatus: string, targetStatus: string) => void;
}

// Separate draggable ticket component to properly use React hooks
const DraggableTicket: React.FC<{ ticket: any; status: string }> = ({ ticket, status }) => {
    const ticketRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (!ticketRef.current) return;

        return draggable({
            element: ticketRef.current,
            getInitialData: () => ({
                ticketId: ticket.id,
                status: status,
            }),
        });
    }, [ticket.id, status]);

    return (
        <div ref={ticketRef}>
            <TicketCard ticket={ticket} />
        </div>
    );
};

export default function Column({ group, onDragEnd }: ColumnProps) {
    const dropzoneRef = React.useRef<HTMLDivElement>(null);
    const [isDraggingOver, setIsDraggingOver] = React.useState(false);
    const [dropEdge, setDropEdge] = React.useState<Edge | null>(null);

    React.useEffect(() => {
        if (!dropzoneRef.current) return;

        return combine(
            dropTargetForElements({
                element: dropzoneRef.current,
                onDrag: ({ location }) => {
                    setIsDraggingOver(true);
                    // Determine edge based on drag location
                    setDropEdge('bottom');
                },
                onDragLeave: () => {
                    setIsDraggingOver(false);
                    setDropEdge(null);
                },
                onDrop: ({ source }) => {
                    setIsDraggingOver(false);
                    setDropEdge(null);
                    const data = source.data;
                    if (data && 'ticketId' in data && 'status' in data && onDragEnd) {
                        onDragEnd(
                            data.ticketId as string,
                            data.status as string,
                            group.status.name
                        );
                    }
                },
            })
        );
    }, [group.status.name, onDragEnd]);

    return (
        <div className="flex h-full flex-col justify-between">
            <Card className="rounded-[6px] p-[10px] ">
                <div className="flex flex-row items-center justify-between gap-[6px]">
                    <div className="flex flex-row items-center gap-[6px] h-full">
                        <div className="flex items-center justify-center">
                            <StatusIcon size={15} color={group.status.color} />
                        </div>
                        <p style={{ color: group.status.color }} className="font-medium text-sm leading-[21px] mb-0 mt-[2px] text-nowrap">
                            {group.status.name}
                        </p>
                        <div className="flex items-center justify-center size-[20px] border border-[#E9ECF2] rounded-full text-sm leading-[12px] text-secondary font-normal">
                            {group.tickets.length}
                        </div>
                    </div>
                    <button className="">
                        <img src={menuIcon} alt="edit" className="size-[20px]" />
                    </button>
                </div>
            </Card>
            <div 
                ref={dropzoneRef}
                className="flex flex-col justify-start h-full mt-[16px] gap-[12px] relative"
            >
                {isDraggingOver && dropEdge && (
                    <DropIndicator edge={dropEdge} gap="12px" />
                )}
                {group.tickets.map((ticket) => (
                    <DraggableTicket 
                        key={ticket.id} 
                        ticket={ticket} 
                        status={group.status.name}
                    />
                ))}
            </div>
            <button 
                onClick={() => {}} 
                className="flex flex-row w-fit items-center gap-[4px] mt-[5px] mb-[15px] group"
            >
                <div className="flex justify-center items-center size-[20px]">
                    <img src={addIcon} alt="add" className="size-[20px]" />
                </div>
                <div className="flex flex-row items-center group-hover:bg-white rounded-[4px]">
                    <button className="hover:bg-[#F4F5F7] rounded-l-[4px] h-[20px] px-[6px] py-auto pb-2 text-sm leading-[21px] font-normal text-primary">
                        Add task
                    </button>
                    <button className="hidden group-hover:flex hover:bg-[#F4F5F7] bg-white rounded-r-[4px] border-l-[1px] border-[#E9ECF2] justify-center items-center size-[20px]">
                        <DropdownIcon size={20} />
                    </button>
                </div>
            </button>
        </div>
    )
}