import { createSlice } from "utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { ContainerState, PLAN_TYPE } from "../types";
import { FormError } from "app/types";

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  profileForm: {
    name: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
    confirmPassword: {
      value: "",
      error: "",
    },
    timezone: {
      value: "",
      error: "",
    },
    isLoading: false,
  },
  setupForm: {
    companySize: {
      value: "",
      error: "",
    },
    referalSource: {
      value: "",
      error: "",
    },
    usedTools: {
      value: [],
      error: "",
    },
    isLoading: false,
  },
};

const formSlice = createSlice({
  name: "OnBoardingState",
  initialState,
  reducers: {
    setProfileFormValue: (
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      state.profileForm[action.payload.name].value = action.payload.value;
      state.profileForm[action.payload.name].error = "";
    },
    setProfileFormErrors: (state, action: PayloadAction<FormError[]>) => {
      action.payload.forEach((error) => {
        state.profileForm[error.name].error = error.error;
      });
    },
    saveProfileRequest: (
      state,
      action: PayloadAction<{ callback?: () => void }>
    ) => {
      state.profileForm.isLoading = true;
    },
    saveProfileRequestComplete: (state) => {
      state.profileForm.isLoading = false;
    },
    setSetupFormValue: (
      state,
      action: PayloadAction<{ name: string; value: string | string[] }>
    ) => {
      state.setupForm[action.payload.name].value = action.payload.value;
      state.setupForm[action.payload.name].error = "";
    },
    saveSetupRequest: (
      state,
      action: PayloadAction<{ callback?: () => void }>
    ) => {
      state.setupForm.isLoading = true;
    },
    saveSetupRequestComplete: (state) => {
      state.setupForm.isLoading = false;
    },
  },
});

export const {
  actions,
  reducer: onBoardingReducer,
  name: onBoardingSlice,
} = formSlice;
