import React from 'react'
import { TICKET_GROUPS } from 'app/containers/Features/containers/Boards/data'
import { useQueryState } from 'nuqs'
import Column from '../../../../components/Column';

export default function Kaban() {
    const [, setTid] = useQueryState('tid');

    const handleDragEnd = (ticketId: string, sourceStatus: string, targetStatus: string) => {
        // Here you would typically update your state/backend
        console.log(`Moving ticket ${ticketId} from ${sourceStatus} to ${targetStatus}`);
        // You can implement the actual state update logic here
    };

    return (
        <div className="flex-shrink-0 w-full h-[calc(100vh-160px)] overflow-x-auto">    
            <div className="w-full max-w-[calc(100vw-76px)] h-full">
                <div className="grid grid-cols-5 gap-[16px] h-full min-w-[max-content]">
                    {
                        TICKET_GROUPS.map((group) => (
                            <div 
                                onClick={() => setTid(group.status.name)} 
                                key={group.status.name} 
                                className="w-[255px]"
                            >
                                <Column 
                                    group={group} 
                                    onDragEnd={handleDragEnd}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}