import React from 'react';
import TabNavigation from './components/TabNavigation';
import FormOne from './containers/FormOne';
import Pricing from './containers/Pricing';
import { useDispatch, useSelector } from 'react-redux';
import { PLAN_TYPE } from '../../types';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from 'app/components/Icons/Logo';
import FormThree from './containers/FormThree';
import FormTwo from './containers/FormTwo';
import PaymentDialog from '../../components/PaymentDialog';
import { useQueryState } from 'nuqs';
import { actions } from 'app/redux/slice';

export default function TeamOnBoarding() {
    // Retrieve activeIndex from Redux state
    const activeIndex = useSelector((state: any) => state.AppState.teamFormActiveIndex);
    const [checkout, setCheckout] = useQueryState('checkout');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const isOnboardingTeamPage = location.pathname === '/onboarding/team';

    // Handle moving to the next step
    const onNext = () => {
        if (activeIndex < 4) {
            dispatch(actions.setTeamFormActiveIndex(activeIndex + 1));
        } else {
            dispatch(
                actions.saveTeamRequest({
                    callback: () => navigate('/app/home'),
                })
            );
        }
    };

    // Handle moving to the previous step
    const onBack = () => {
        if (activeIndex > 1) {
            dispatch(actions.setTeamFormActiveIndex(activeIndex - 1));
        }
    };

    // Handle plan selection and completion
    const onComplete = (plan: PLAN_TYPE) => {
        dispatch(
            actions.setTeamPlan({
                value: plan,
            })
        );
    };

    return (
        <div className="container min-h-[580px] flex flex-col justify-start max-w-[770px]">
            {isOnboardingTeamPage && (
                <div className="flex flex-col items-center gap-[20px]">
                    <Logo size={72} />
                    <h1 className="text-[40px] leading-[50px] font-semibold mb-4 text-center">
                        Create new team
                    </h1>
                </div>
            )}
            <TabNavigation activeIndex={activeIndex} />
            {activeIndex === 1 && <FormOne onProceed={onNext} isOnboardingTeamPage={isOnboardingTeamPage} />}
            {activeIndex === 2 && <FormTwo onProceed={onNext} onBack={onBack} isOnboardingTeamPage={isOnboardingTeamPage} />}
            {activeIndex === 3 && <FormThree onProceed={onNext} isOnboardingTeamPage={isOnboardingTeamPage} />}
            {activeIndex === 4 && <Pricing onProceed={(plan) => setCheckout(plan)} />}
            {activeIndex === 4 && checkout && (
                <PaymentDialog
                    onProceed={() => onComplete(checkout as PLAN_TYPE)}
                    onClose={() => setCheckout(null)}
                />
            )}
        </div>
    );
}
