import { createSlice } from "utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { ContainerState } from "../types";
import { TicketGroup } from "../../../types";
// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
    groups: [],
    isLoading: false,
};

const formSlice = createSlice({
  name: "TicketGroupsState",
  initialState,
  reducers: {
    setGroups: (state, action: PayloadAction<TicketGroup[]>) => {
        state.groups = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload;
    },
    fetchTicketGroups: (state) => {
        state.isLoading = true;
    },
    updateTicketStatus: (state, action: PayloadAction<{ ticketId: string; sourceStatus: string; targetStatus: string }>) => {
        const { ticketId, sourceStatus, targetStatus } = action.payload;
        const sourceGroup = state.groups.find(g => g.status.name === sourceStatus);
        const targetGroup = state.groups.find(g => g.status.name === targetStatus);
        
        if (sourceGroup && targetGroup) {
            const ticketIndex = sourceGroup.tickets.findIndex(t => t.id === ticketId);
            if (ticketIndex !== -1) {
                const ticket = { ...sourceGroup.tickets[ticketIndex] };
                ticket.status = { ...ticket.status, name: targetStatus };
                
                // Remove from source group
                sourceGroup.tickets.splice(ticketIndex, 1);
                
                // Add to target group
                targetGroup.tickets.push(ticket);
            }
        }
    },
  },
});

export const { actions, reducer: ticketGroupsReducer, name: ticketGroupsSlice } = formSlice;
