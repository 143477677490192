import React from 'react';
import Card from '../Card';
import { PLAN, PLAN_TYPE } from 'app/containers/OnBoarding/types';

export const PRICES:PLAN[] = [
    {
        title: PLAN_TYPE.FREE,
        caption: 'Perfect for startups and freelancers looking for a cost-effective solution',
        price: 0,
        features: [
            '5 GB storage limit',
            'Maximum 3 active projects',
            'Unlimited number of kanban and list boards',
            'Unlimited number of team members',
            'Users team roles for role based environment and data access',
            '1 Month archive history',
            'Backlog management and prioritization',
            '10 MB maximum file size',
            'Maximum 5 custom fields',
            'Community support',
            'Timesheet management'
        ]
    },
    {
        title: PLAN_TYPE.BUSINESS,
        caption: 'Perfect for businesses seeking scalable solutions to streamline workflows and boost collaboration',
        price: 7,
        features: [
            'Advanced boards settings',
            'Roadmap managements',
            '200 MB maximum file size',
            'Unlimited custom fields',
            'Custom fields permissions',
            'Software components',
            '2 factor authentication',
            'Custom roles creation',
            '250 GB storage limit',
            'Unlimited projects',
            '6 months archive history',
            'Status permissions, transitions and automation rules'
        ]
    },
    {
        title: PLAN_TYPE.PROFESSIONAL,
        caption: 'Perfect for professionals looking to optimize productivity and collaboration',
        price: 12,
        features: [
            'White labeling',
            '1GB maximum file size',
            'Unlimited activity log history',
            'Unlimited storage',
            '5 years archive history',
            'Priority support'
        ]
    },
]

const Monthly = ({
    onClick
}:{
    onClick: (plan: PLAN_TYPE) => void;
}) => {
    return (
        <>
            {
                PRICES.map((item) => (
                    <Card key={item?.title} item={item} onClick={onClick} />
                ))
            }
        </>
    );
}

export default Monthly;
