import React from "react";

interface LinkTicketIconProps {
    color?: string;
    size?: number;
}

const LinkTicketIcon = ({ color = "#2CBB93", size = 16 }: LinkTicketIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
            <path d="M13.4928 1.32812L2.51241 1.32812C1.86266 1.32812 1.33594 1.85485 1.33594 2.5046L1.33594 13.485C1.33594 14.1347 1.86266 14.6615 2.51241 14.6615L13.4928 14.6615C14.1425 14.6615 14.6693 14.1347 14.6693 13.485L14.6693 2.5046C14.6693 1.85485 14.1425 1.32812 13.4928 1.32812Z" stroke={color} strokeWidth="1.5" strokeLinejoin="round"/>
            <path d="M7.47596 6.90274L9.42155 4.87701C9.95501 4.34355 10.8322 4.35495 11.3804 4.90348C11.9285 5.45164 11.9403 6.32885 11.4068 6.8623L10.7046 7.60605M5.64728 8.97517C5.45978 9.16267 5.07192 9.53767 5.07192 9.53767C4.53809 10.0711 4.52339 11.0222 5.07192 11.5708C5.61971 12.1186 6.49692 12.1307 7.03074 11.5969L8.92927 9.87333" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.56002 8.8182C7.3158 8.57555 7.16804 8.25245 7.14421 7.90901C7.13013 7.71634 7.15764 7.5229 7.22488 7.3418C7.29212 7.1607 7.39751 6.99617 7.53392 6.85938M8.90487 7.91121C9.45303 8.45938 9.4648 9.33658 8.93134 9.87041" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default LinkTicketIcon;