import OtherAgileIcon from 'app/components/Icons/OtherAgileIcon'
import OtherEpicIcon from 'app/components/Icons/OtherEpicIcon'
import OtherReleaseIcon from 'app/components/Icons/OtherReleaseIcon'
import { Switch } from 'app/components/Switch'
import { selectTeamProjectDefaultCustomization } from 'app/redux/selector'
import { actions } from 'app/redux/slice'
import React from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

const DefaultCustomizationList = [
    {
        id: "agileDevelopmentAndSprints",
        title: 'Agile Development and Sprints',
        caption: 'Enables teams to adopt Agile methodologies, particularly Scrum',
        color: "#6bd60e26",
        icon: <OtherAgileIcon />
    },
    {
        id: "epicsAndStories",
        title: 'Epics and Stories',
        caption: 'Facilitates the breakdown of large pieces of work into manageable components.',
        color: "#16e4e426",
        icon: <OtherEpicIcon />
    },
    {
        id: "releaseManagement",
        title: 'Release Management',
        caption: 'Facilitates the planning, tracking, and execution of software releases.',
        color: "#ff797926",
        icon: <OtherReleaseIcon />
    }
]

export default function DefaultCustomization() {
    const dispatch = useDispatch();
    const defaultCustomization = useSelector(selectTeamProjectDefaultCustomization);
    return (
        <div className="flex flex-col gap-[20px] w-full">
            <h6 className="text-lg leading-[27px] font-medium text-black text-center">Customize defaults for Starter</h6>
            <div className="flex flex-col gap-[16px]">
                {
                    DefaultCustomizationList.map((item) => (
                        <div className="flex flex-row justify-between items-center border border-primary rounded-[4px] px-[16px] py-[14px]">
                            <div className="flex flex-row gap-[10px] items-center">
                                <div style={{ backgroundColor: item?.color }} className="size-[26px] min-w-[26px] rounded-[4px] flex items-center justify-center">
                                    {item?.icon}
                                </div>
                                <div className="flex flex-col">
                                    <h6 className="text-xs leading-[18px] font-normal text-black">{item?.title}</h6>
                                    <p className="text-xs leading-[18px] font-normal text-secondary">{item?.caption}</p>
                                </div>
                            </div>
                            <Switch checked={defaultCustomization[item.id]} onCheckedChange={(value) => dispatch(actions.setTeamProjectDefaultCustomization({ name: item.id, value }))} />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
