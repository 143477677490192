import React from "react";

interface CouponIconProps {
    color?: string;
    size?: number;
}

export const CouponIcon = ({ color = "#75C75D", size = 34 }: CouponIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size + 2} viewBox="0 0 34 36" fill="none">
            <path d="M15.6085 1.42117C16.3841 0.669889 17.6159 0.669888 18.3915 1.42117L19.4935 2.48856C20.0288 3.00713 20.8081 3.185 21.5154 2.95006L22.9713 2.46648C23.9961 2.12611 25.106 2.66061 25.4788 3.674L26.0085 5.1138C26.2658 5.81329 26.8907 6.31167 27.6299 6.40689L29.1515 6.6029C30.2225 6.74086 30.9905 7.704 30.8867 8.77878L30.7392 10.3058C30.6676 11.0477 31.0144 11.7679 31.6391 12.1744L32.9249 13.0112C33.83 13.6001 34.1041 14.8011 33.5442 15.7244L32.7488 17.0363C32.3623 17.6736 32.3623 18.4729 32.7488 19.1102L33.5442 20.422C34.1041 21.3453 33.83 22.5464 32.9249 23.1353L31.6391 23.9721C31.0144 24.3786 30.6676 25.0988 30.7392 25.8407L30.8867 27.3677C30.9905 28.4425 30.2225 29.4056 29.1515 29.5436L27.6299 29.7396C26.8907 29.8348 26.2658 30.3332 26.0085 31.0327L25.4788 32.4725C25.106 33.4859 23.9961 34.0204 22.9713 33.68L21.5154 33.1964C20.8081 32.9615 20.0288 33.1394 19.4935 33.6579L18.3915 34.7253C17.6159 35.4766 16.3841 35.4766 15.6085 34.7253L14.5065 33.6579C13.9712 33.1394 13.1919 32.9615 12.4846 33.1964L11.0287 33.68C10.0039 34.0204 8.89402 33.4859 8.52121 32.4725L7.99154 31.0327C7.73421 30.3332 7.10927 29.8348 6.37005 29.7396L4.84849 29.5436C3.77755 29.4056 3.00947 28.4425 3.11328 27.3677L3.26077 25.8407C3.33242 25.0988 2.9856 24.3786 2.36091 23.9721L1.07507 23.1353C0.170046 22.5464 -0.104076 21.3453 0.455783 20.422L1.25122 19.1102C1.63766 18.4729 1.63766 17.6736 1.25122 17.0363L0.455783 15.7244C-0.104076 14.8011 0.170046 13.6001 1.07507 13.0112L2.36091 12.1744C2.9856 11.7679 3.33242 11.0477 3.26077 10.3058L3.11328 8.77878C3.00947 7.704 3.77755 6.74086 4.84849 6.6029L6.37005 6.40689C7.10927 6.31167 7.73421 5.81329 7.99154 5.1138L8.52121 3.674C8.89402 2.66061 10.0039 2.12611 11.0287 2.46648L12.4846 2.95006C13.1919 3.185 13.9712 3.00713 14.5065 2.48856L15.6085 1.42117Z" fill={color}/>
        </svg>
    )
};
