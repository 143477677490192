import React from "react";

export default function DescriptionIcon({
    size = 16,
    color = "#25CC38"
}: {
    readonly size?: number,
    readonly color?: string
}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 4.6C2 4.44087 2.05926 4.28826 2.16475 4.17574C2.27024 4.06321 2.41332 4 2.5625 4H13.4375C13.5867 4 13.7298 4.06321 13.8352 4.17574C13.9407 4.28826 14 4.44087 14 4.6C14 4.75913 13.9407 4.91174 13.8352 5.02426C13.7298 5.13679 13.5867 5.2 13.4375 5.2H2.5625C2.41332 5.2 2.27024 5.13679 2.16475 5.02426C2.05926 4.91174 2 4.75913 2 4.6ZM2 8C2 7.84087 2.05926 7.68826 2.16475 7.57574C2.27024 7.46321 2.41332 7.4 2.5625 7.4H13.4375C13.5867 7.4 13.7298 7.46321 13.8352 7.57574C13.9407 7.68826 14 7.84087 14 8C14 8.15913 13.9407 8.31174 13.8352 8.42426C13.7298 8.53679 13.5867 8.6 13.4375 8.6H2.5625C2.41332 8.6 2.27024 8.53679 2.16475 8.42426C2.05926 8.31174 2 8.15913 2 8ZM2.5625 10.8C2.41332 10.8 2.27024 10.8632 2.16475 10.9757C2.05926 11.0883 2 11.2409 2 11.4C2 11.5591 2.05926 11.7117 2.16475 11.8243C2.27024 11.9368 2.41332 12 2.5625 12H9.6875C9.83668 12 9.97976 11.9368 10.0852 11.8243C10.1907 11.7117 10.25 11.5591 10.25 11.4C10.25 11.2409 10.1907 11.0883 10.0852 10.9757C9.97976 10.8632 9.83668 10.8 9.6875 10.8H2.5625Z" fill={color}/>
        </svg>
    );
}