import React from "react";

interface ActivityTypeIconProps {   
    size?: string;
    color?: string;
}

const ActivityTypeIcon = ({ size = "17", color = "5A8DF1" }: ActivityTypeIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 17 17" fill="none">
            <path d="M12.4398 1.69041L12.4399 1.69021C12.7132 1.13305 13.2374 0.734307 13.8576 0.608874C14.5065 0.480063 15.1867 0.692167 15.6621 1.17445L15.6622 1.17462C16.101 1.61654 16.2974 2.13129 16.2595 2.71499C16.1871 3.79417 15.3398 4.59755 14.276 4.60515L12.4398 1.69041ZM12.4398 1.69041C12.3586 1.85457 12.3069 1.98482 12.2751 2.11921C12.2434 2.25357 12.2308 2.39502 12.2308 2.58217L12.2308 2.58262M12.4398 1.69041L12.2308 2.58262M12.2308 2.58262C12.227 3.14515 12.4242 3.62573 12.8196 4.01673L12.8196 4.01673M12.2308 2.58262L12.8196 4.01673M12.8196 4.01673L12.8198 4.01696M12.8196 4.01673L12.8198 4.01696M12.8198 4.01696C13.2191 4.41568 13.6965 4.60895 14.276 4.60515L12.8198 4.01696ZM14.5267 1.74188L14.5267 1.74187L14.5277 1.7422C14.8765 1.84969 15.148 2.24231 15.148 2.62922C15.148 2.7454 15.097 2.88934 15.0273 3.01571C14.9575 3.14244 14.8618 3.26447 14.761 3.3316L14.7609 3.3317C14.5694 3.45848 14.3658 3.51375 14.1672 3.49387C13.9688 3.474 13.7812 3.3796 13.6207 3.21739C13.1731 2.7743 13.2784 2.08069 13.8383 1.80115C13.9574 1.7417 14.0649 1.70703 14.1768 1.69781C14.2885 1.6886 14.4 1.70517 14.5267 1.74188Z" fill={color} stroke={color} stroke-width="0.133333"/>
            <path d="M5.32671 1.36189C4.45668 1.4052 3.82852 1.50265 3.31588 1.67227C2.18592 2.04762 1.22563 3.00763 0.850181 4.13727C0.572202 4.98179 0.5 5.92376 0.5 8.82906C0.5 11.7344 0.572202 12.6763 0.850181 13.5209C1.21841 14.6252 2.1787 15.5997 3.26534 15.9678C4.12455 16.2601 5.09206 16.3359 8.00903 16.3359C11.3556 16.3359 12.2473 16.2385 13.1895 15.7801C14.1029 15.3326 14.8502 14.4737 15.1715 13.5064C15.2256 13.3476 15.2978 13.0481 15.3375 12.8351C15.4819 12.0339 15.4964 11.6189 15.5 8.91207C15.5 6.3388 15.5 6.30632 15.4242 6.20888C15.2834 6.0176 15.1679 5.95985 14.9404 5.95985C14.713 5.95985 14.5975 6.0176 14.4567 6.20888C14.3809 6.30632 14.3809 6.35685 14.3592 8.94816C14.3448 10.5542 14.3195 11.738 14.2942 11.969C14.1643 13.1527 13.9368 13.7013 13.3448 14.2643C13.0235 14.5747 12.7274 14.7552 12.3339 14.8851C11.5722 15.1377 10.5433 15.1955 7.43141 15.1702C6.18592 15.1594 5.05235 15.1341 4.85018 15.1125C3.70578 14.9861 3.13899 14.7516 2.59025 14.1813C1.97292 13.5461 1.77798 12.9362 1.69134 11.4096C1.6444 10.5686 1.6444 7.08949 1.69134 6.24858C1.77798 4.71833 1.97292 4.11561 2.59025 3.47681C3.1426 2.90657 3.70939 2.67198 4.85018 2.54567C5.09928 2.5204 6.22563 2.49514 7.88989 2.4807C10.4819 2.45905 10.5325 2.45905 10.63 2.38326C10.8213 2.24251 10.8791 2.12702 10.8791 1.89964C10.8791 1.67227 10.8213 1.55678 10.63 1.41603C10.5325 1.34024 10.4964 1.34024 8.19675 1.33663C6.91155 1.33302 5.61913 1.34385 5.32671 1.36189Z" fill={color}/>
            <path d="M12.1836 5.55176C12.1238 5.57832 12.034 5.64282 11.9891 5.69214C11.9443 5.74147 11.6189 6.37509 11.2674 7.09978C10.5643 8.54915 10.5082 8.62883 10.1305 8.74265C9.89865 8.81474 9.83134 8.81474 9.58825 8.73886C9.31152 8.65539 9.12827 8.47327 8.92259 8.0635C8.52618 7.28569 7.84929 6.85316 7.02282 6.84936C6.39829 6.84557 5.96074 7.0201 5.51572 7.44505L5.23524 7.71823L4.55461 9.12207C4.18064 9.89229 3.86277 10.5752 3.84781 10.6322C3.7618 11.0078 4.1582 11.4024 4.55087 11.3265C4.82013 11.2734 4.88371 11.1747 5.57181 9.75949C6.13277 8.60606 6.24122 8.40497 6.37585 8.28735C6.76104 7.95347 7.32199 7.98003 7.66231 8.35185C7.72588 8.42015 7.85303 8.62124 7.94278 8.79957C8.41399 9.73293 9.42745 10.192 10.4297 9.92264C10.785 9.82779 11.0505 9.66464 11.3534 9.3649L11.6451 9.07275L12.322 7.6727C12.8979 6.48512 13.0026 6.24988 13.0026 6.10191C13.0026 5.67696 12.5613 5.38102 12.1836 5.55176Z" fill={color}/>
        </svg>
    )
}

export default ActivityTypeIcon;