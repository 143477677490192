import React from 'react';

interface PrimaryCheckIconProps {
    readonly size?: number;
}

export default function PrimaryCheckIcon({ size=20 }: PrimaryCheckIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size + 1} viewBox="0 0 20 21" fill="none">
            <path d="M10 0.0732422C4.47715 0.0732422 0 4.55039 0 10.0732C0 15.5961 4.47715 20.0732 10 20.0732C15.5228 20.0732 20 15.5961 20 10.0732C19.9936 4.55306 15.5202 0.0796712 10 0.0732422Z" fill="#EFF4FE"/>
            <path d="M15.7741 6.90686L10.07 14.6477C9.93392 14.8282 9.73107 14.9464 9.50698 14.9759C9.28289 15.0054 9.05636 14.9436 8.8783 14.8044L4.80496 11.5477C4.44552 11.26 4.38731 10.7355 4.67496 10.376C4.96261 10.0166 5.48718 9.95838 5.84663 10.246L9.2433 12.9635L14.4325 5.92103C14.6026 5.66568 14.8993 5.52451 15.2048 5.55356C15.5103 5.5826 15.7751 5.77717 15.8941 6.06C16.013 6.34284 15.967 6.66818 15.7741 6.90686Z" fill="#5A8DF1"/>
        </svg>
    );
}