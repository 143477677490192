import Input from 'app/components/Input'
import React from 'react'
import plus from 'assets/svgs/ic_plus_primary.svg'
import Button from 'app/components/Button';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { validateTeamFormMembers } from 'app/containers/OnBoarding/helpers';
import { Select } from 'app/components/Select';
import { Roles } from 'app/containers/OnBoarding/data';
import InfoIcon from 'app/components/Icons/InfoIcon';
import TrashIcon from 'app/components/Icons/TrashIcon';
import { selectTeamForm } from 'app/redux/selector';
import { actions } from 'app/redux/slice';

interface FormThreeProps {
    readonly onProceed: () => void;
    readonly isOnboardingTeamPage: boolean;
}

export default function FormThree({ onProceed, isOnboardingTeamPage }: FormThreeProps) {
    const { members } = useSelector(selectTeamForm);
    const dispatch = useDispatch();


    const handleSubmit = () => {
        const errors = validateTeamFormMembers(members);
        if (errors.length > 0) {
            errors.forEach((error) => {
                const name = error.name.split('-')[0];
                const index = parseInt(error.name.split('-')[1]);
                const _ = { index, name, error: error.error }
                dispatch(actions.updateTeamMemberError(_));
            });
            return;
        }
        onProceed();
    };

    return (
        <div className='flex flex-col gap-4'>
            <h5 className='text-lg text-center text-black font-medium leading-[27px]'>Add members</h5>
            {/* Add member and role */}
            <div className='flex flex-col gap-4'>
                {members?.map((member, index) => (
                    <div key={index} className='flex flex-row gap-[16px] w-full relative'>
                        <Input
                            containerClassName="w-full"
                            label='Member'
                            id={`member-${index}`}
                            value={member.name.value}
                            onChange={(e) => dispatch(actions.updateTeamMember({ index, name: 'name', value: e.target.value }))}
                            error={member.name.error}
                        />
                        <Select
                            options={Roles}
                            containerClassName="w-full"
                            placeholder="Choose role"
                            label='Role'
                            id={`role-${index}`}
                            value={member.role.value}
                            error={member.role.error}
                            onChange={(value) => dispatch(actions.updateTeamMember({ index, name: 'role', value }))}
                        />
                        {
                            index > 0 && (
                                <button
                                    className='flex absolute right-[-22px] top-[38px] transform items-center justify-center'
                                    onClick={() => dispatch(actions.removeTeamMember({ index }))}
                                >
                                    <TrashIcon />
                                </button>
                            )
                        }
                    </div>
                ))}
            </div>
            {
                members.length < 5 ? (
                    <button
                        className='flex flex-row gap-[6px] items-center w-fit'
                        onClick={() => dispatch(actions.addTeamMember())}
                    >
                        <img src={plus} alt="plus" width={16} height={16} />
                        <p className='text-sm text-primary font-medium leading-[21px] mb-0 mt-1'>Member</p>
                    </button>
                ) : (
                    <p className='text-[11px] leading-[18px] text-danger flex items-center gap-[4px]'>
                        <InfoIcon size="16" color="#EB5757" />
                        <span className='mt-[2px]'>You've reached the member limit for this step.</span>
                    </p>
                )
            }
            {isOnboardingTeamPage && (
                <div className='flex flex-col justify-center items-center gap-[16px] mt-6'>
                    <Button
                        className='w-[390px] h-[56px]'
                        onClick={handleSubmit}
                    >
                        Continue
                    </Button>
                    <Link onClick={onProceed} to="#" className='text-black font-normal leading-[24px] text-center mb-0 mt-1'>
                        Skip for now
                    </Link>
                </div>
            )}
        </div>
    )
}
