import React from "react";

interface InfoIconProps {
    size?: string;
    color?: string;
}

const InfoIcon = ({ size = "18", color = "#79808A" }: InfoIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18" fill="none">
  <path d="M8.5 7.98369C8.5 7.85108 8.55268 7.7239 8.64645 7.63013C8.74022 7.53637 8.86739 7.48369 9 7.48369C9.13261 7.48369 9.25978 7.53637 9.35355 7.63013C9.44732 7.7239 9.5 7.85108 9.5 7.98369L9.5 13.9837C9.5 14.1163 9.44732 14.2435 9.35355 14.3372C9.25979 14.431 9.13261 14.4837 9 14.4837C8.86739 14.4837 8.74021 14.431 8.64645 14.3372C8.55268 14.2435 8.5 14.1163 8.5 13.9837L8.5 7.98369ZM8.64645 3.70113C8.74021 3.60737 8.86739 3.55469 9 3.55469C9.13261 3.55469 9.25979 3.60737 9.35355 3.70113C9.44732 3.7949 9.5 3.92208 9.5 4.05469C9.5 4.1873 9.44732 4.31447 9.35355 4.40824C9.25979 4.50201 9.13261 4.55469 9 4.55469C8.86739 4.55469 8.74021 4.50201 8.64645 4.40824C8.55268 4.31447 8.5 4.1873 8.5 4.05469C8.5 3.92208 8.55268 3.7949 8.64645 3.70113Z" stroke={color}/>
            <circle cx="9" cy="9" r="8.25" stroke={color} strokeWidth="1.5" />
        </svg>  
    )
}

export default InfoIcon;