import React from 'react'
import Kaban from '../../Home/containers/Board/containers/Kaban'
import List from '../../Home/components/List'

export default function Tickets({
    id
}: {
    id: string
}) {
    return (
        <>
            {
                id && (parseInt(id) > 2) ? 
                <Kaban /> : 
                <List />
            }
        </>
    )
}
