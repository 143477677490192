import React from "react";

interface BorderedDropdownIconProps {
    size?: number;
    color?: string;
}

export const BorderedDropdownIcon = ({ size = 16, color = "#79808A" }: BorderedDropdownIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
            <rect x="2.5" y="2.5" width="11" height="11" rx="0.833333" stroke={color}/>
            <path d="M5.33594 6.66406L7.7669 9.09503C7.89708 9.2252 8.10813 9.2252 8.23831 9.09503L10.6693 6.66406" stroke={color} stroke-linecap="round"/>
        </svg>
    )
};
