// src/app/containers/Features/containers/User/data.ts

export const userDetailsData = {
  name: "John Doe",
  initials: "JD",
  email: "john.doe@example.com",
  role: "Administrator",
  status: "Online",
  lastLogin: "2024-11-14 10:30 AM",
  localTime: "10:30 AM",
};


export const memberData = [
  {
    id: "abc1",
    name: "John Doe",
    role: "Developer",
    email: "john.snow@example.com",
    team: "Digital Innovators Collective",
    status: "Online",
    lastLogin: "1 Sep, 2:15 pm",
    localTime: "3:00 pm",
    location: "Israel, Tel Aviv",
    spokenLanguages: "English, Hebrew",
    mobileNumber: "+972 45 569 5236",
    creationDate: "10 Jun, 2023",
  },
  {
    id: "abc2",
    name: "Jane Smith",
    role: "Project Manager",
    email: "smith@example.com",
    team: "Digital Innovators Collective",
    status: "Online",
    lastLogin: "2 Sep, 3:00 pm",
    localTime: "3:00 pm",
    location: "Israel, Tel Aviv 1",
    spokenLanguages: "English, Hebrew",
    mobileNumber: "+972 45 569 5237",
    creationDate: "19 Jun, 2023",
  },
];
