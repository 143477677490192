import React from 'react'

interface CustomizeColumnsIconProps {
    size: string
    color: string
}

export default function CustomizeColumnsIcon({ size, color }: CustomizeColumnsIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M3.33203 8.52112H16.6654M8.51722 3.33594V16.6693M3.33203 4.81742C3.33203 4.42451 3.48812 4.04768 3.76595 3.76985C4.04378 3.49202 4.4206 3.33594 4.81351 3.33594H15.1839C15.5768 3.33594 15.9536 3.49202 16.2314 3.76985C16.5093 4.04768 16.6654 4.42451 16.6654 4.81742V15.1878C16.6654 15.5807 16.5093 15.9575 16.2314 16.2354C15.9536 16.5132 15.5768 16.6693 15.1839 16.6693H4.81351C4.4206 16.6693 4.04378 16.5132 3.76595 16.2354C3.48812 15.9575 3.33203 15.5807 3.33203 15.1878V4.81742Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
