import React from 'react';
import Button from 'app/components/Button';
import { PLAN, PLAN_TYPE, PLANS } from 'app/containers/OnBoarding/types';
import { Link, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import HeartIcon from 'app/components/Icons/HeartIcon';
import { CheckIcon } from 'app/components/Icons';

interface CardProps {
    item: PLAN;
    onClick: (plan: PLAN_TYPE) => void;
}

const getPlan = (plan: PLANS) => {
    return plan === PLANS.MONTHLY ? "month" : "year";
}

const Card = ({
    item,
    onClick,
}:CardProps) => {
    const [searchParams] = useSearchParams();
    const plan = searchParams.get('plan') as PLANS ?? PLANS.MONTHLY;
    return (
        <Link className={clsx('h-full', {"pt-[12px]": item.title !== PLAN_TYPE.BUSINESS})} onClick={() => onClick(item?.title)} to={`#`}>
            <div className="relative flex flex-col gap-[16px] p-[18.85px] min-w-[230px] h-full border border-[#CDCEE8] rounded-[5.89px] hover:border-primary hover:bg-primary100 transition-all duration-300 cursor-pointer group overflow-hidden">
                {
                    item.title === PLAN_TYPE.BUSINESS &&
                    <div className="flex items-center justify-center h-[24px] bg-primary absolute top-0 right-0 z-10 w-full gap-[4px]">
                        <HeartIcon size={16} color="#fff" />
                        <span className="text-xs leading-[10.611px] text-white">Most Popular</span>
                    </div>

                }
                <h3 className={clsx("text-[22px] leading-[33px] font-bold text-[#253858] group-hover:text-primary transition-colors duration-300", {"pt-[12px]": item.title === PLAN_TYPE.BUSINESS})}>${item?.price}<span className="text-[10px] leading-[10.611px] pl-[4.42px] text-secondary font-normal">seat/{getPlan(plan)}</span></h3>
                <div className="flex flex-col gap-[10px]">
                    <h2 className="text-sm leading-[10.611px] font-bold text-black capitalize group-hover:!text-primary transition-colors duration-300">{item?.title}</h2>
                    <p className="text-[10px] leading-[15px] text-secondary max-w-60">
                        {item?.caption}
                    </p>
                </div>
                <ul className="text-accent-foreground text-left h-full">
                    {
                        item?.features.map((feature, index) => (
                            <li key={index} className="flex items-center gap-[6px] mb-[9.42px] text-[8.83px] leading-[8.54px] text-[#02134099]">
                                <div className="w-[10.611px] h-[10.611px] rounded-full flex items-center justify-center bg-[#75C75D]">
                                    <CheckIcon size={8.489} color="#fff" />
                                </div>
                                <span style={{ width: '90%'}}>{feature}</span>
                            </li>
                        ))
                    }
                </ul>
                <div className="flex justify-center items-center">
                    <Button className="!text-[#2C345F] border-[0.59px] border-[#CDCEE8] text-[10.6px] h-[32px] w-[190px] bg-white !rounded-[14.13px] font-medium group-hover:bg-primary group-hover:text-white transition-colors duration-300">
                        <span className="group-hover:text-white transition-colors duration-300">Choose Plan</span>
                    </Button>
                </div>
            </div>
        </Link>
    );
}

export default Card;
