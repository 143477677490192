import React from "react";
import close from "assets/svgs/ic_close.svg";
import { Dialog, DialogContent } from "../Dialog";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/redux/slice";

interface DialogProps {
    onClose: () => void;
    onSubmit?: () => void;
    submitText?: string;
    title: string;
    children: React.ReactNode;
    contentClassName?: string;
    modalClassName?: string;
    isStepNavigation?: boolean;
}

export default function Modal({
    onClose,
    title = "Title",
    children,
    contentClassName = "",
    modalClassName = "",
    onSubmit,
    submitText = "Archive",
    isStepNavigation = false,
}: DialogProps) {
    const selectTeamFormActiveIndex = (state: any) => state.AppState.teamFormActiveIndex;
    const dispatch = useDispatch();
    const activeIndex = useSelector(selectTeamFormActiveIndex);

    const handleBack = () => {
        if (activeIndex > 1) {
            dispatch(actions.setTeamFormActiveIndex(activeIndex - 1));
        }
    };

    const handleNext = () => {
        if (activeIndex < 4) {
            dispatch(actions.setTeamFormActiveIndex(activeIndex + 1));
        } else {
            console.log("Finish"); // Handle finish logic
        }
    };

    return (
        <Dialog open={true} onOpenChange={onClose}>
            <DialogContent className={`!p-0 border-none shadow-lg rounded-lg ${modalClassName}`}>
                <div className="relative flex flex-col bg-white rounded-lg shadow-xl overflow-hidden">
                    {/* Header */}
                    <div className="flex justify-between items-center bg-gray-100 border-b border-gray-300 px-6 py-4">
                        <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
                        <button onClick={onClose} className="p-1">
                            <img src={close} alt="close" className="w-6 h-6" />
                        </button>
                    </div>

                    {/* Content */}
                    <div
                        className={`flex-grow p-6 overflow-y-auto ${contentClassName}`}
                        style={{ maxHeight: "75vh" }}
                    >
                        {children}
                    </div>

                    {/* Footer */}
                    <div className="flex justify-end items-center gap-4 bg-gray-100 border-t border-gray-300 px-6 py-4">
                        {isStepNavigation ? (
                            <>
                                {activeIndex === 3 ? (
                                    <button
                                        onClick={handleNext}
                                        className="bg-transparent text-secondary border border-secondary rounded-md px-4 py-2 text-sm font-medium hover:bg-secondary-light transition"
                                    >
                                        Skip for now
                                    </button>
                                ) : (
                                    <button
                                        onClick={handleBack}
                                        disabled={activeIndex === 1}
                                        className="bg-transparent text-secondary border border-secondary rounded-md px-4 py-2 text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed"
                                    >
                                        Back
                                    </button>
                                )}
                                {activeIndex < 4 && (
                                    <button
                                        onClick={handleNext}
                                        className="bg-primary text-white rounded-md px-4 py-2 text-sm font-medium hover:bg-primary-dark transition"
                                    >
                                        Next
                                    </button>
                                )}
                            </>
                        ) : (
                            <>
                                <button
                                    onClick={onClose}
                                    type="button"
                                    className="bg-[transparent] text-secondary border border-secondary rounded-[4px] w-[106px] h-[36px] text-sm leading-[21px] font-normal text-center"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={onClose}
                                    type="button"
                                    className="bg-primary text-white rounded-[4px] w-[106px] h-[36px] text-sm leading-[21px] font-normal text-center"
                                >
                                    {submitText}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}