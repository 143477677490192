import { UnCheckedIcon, CheckedIcon } from "app/components/Icons";
import clsx from "clsx";
import React from "react";
import { PLANS } from "../types";
import { CouponIcon } from "app/components/Icons/CouponIcon";

interface BillCardProps {
    isChecked?: boolean;
    plan?: PLANS;
    discount?: string;
}

const getDetails = (plan: PLANS) => {
    switch (plan) {
        case PLANS.MONTHLY: 
            return {
                title: 'Billed monthly',
                price: 7,
            }
        case PLANS.YEARLY:
            return {
                title: 'Billed yearly',
                price: 5,
            }
        default:
        return {
            title: 'Billed monthly',
            price: 5,
        }
    }
}

export const BillCard = ({ isChecked = false, plan = PLANS.MONTHLY, discount }: BillCardProps) => {
    const { title, price } = getDetails(plan);
    return (
        <div className={clsx("relative flex flex-col items-center w-full py-[20px] border border-[#E9ECF2] rounded-[4px] gap-4 group hover:border-primary hover:cursor-pointer hover:bg-primary100", isChecked && "border-primary bg-primary100")}>
            {
                discount && (
                    <div className="absolute top-[-8px] right-[-8px] flex items-center justify-center w-[36px] h-[36px]">
                        <div className="absolute top-0 left-0 right-0 bottom-0">
                            <CouponIcon size={34} />
                        </div>
                        <p className="relative text-[10.667px] leading-[16px] font-normal text-white z-10">
                            {discount}
                        </p>
                    </div>
                )
            }
            <div className="flex flex-row items-center gap-[6px]">
                {
                    isChecked ? <CheckedIcon color="#5A8DF1" /> : <UnCheckedIcon />
                }
                <h6 className="text-base leading-[24px] font-medium text-secondary">{title}</h6>
            </div>
            <div className={clsx("font-medium", isChecked ? "text-primary" : "text-black")}>
                <sup className="text-[14px] leading-[21px] font-normal">$</sup>
                <span className="text-[32px] leading-[46px] font-normal">{price}</span>
            </div>
            <p className="text-sm leading-[24px] font-normal text-[#BDC0C3] uppercase">seat per month</p>
        </div>
    )
}