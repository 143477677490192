import Button from 'app/components/Button'
import Input from 'app/components/Input'
import { validateTeamFormName } from 'app/containers/OnBoarding/helpers';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DefaultCustomization from './DefaultCustomization';
import { actions } from 'app/redux/slice';
import { selectTeamForm, selectTeamProjectName } from 'app/redux/selector';

interface FormTwoProps {
    readonly onProceed: () => void;
    readonly onBack: () => void;
    readonly isOnboardingTeamPage: boolean;
}

export default function FormTwo({ onProceed, onBack, isOnboardingTeamPage }: FormTwoProps) {
    const name = useSelector(selectTeamProjectName);
    const { isLoading } = useSelector(selectTeamForm);
    const dispatch = useDispatch();
    const handleSubmit = () => {
        const errors = validateTeamFormName(name);
        if (errors.length > 0) {
            dispatch(actions.setTeamFormErrors(errors));
            return;
        }
        onProceed();
    };
    return (
        <div className='flex flex-col items-center gap-[32px] mb-[56px]'>
            <Input
                label="Enter project name"
                id="project-name"
                containerClassName="w-[390px]"
                labelClassName="!text-lg text-center text-black font-medium !leading-[27px] mb-[10px]"
                value={name.value}
                onChange={(e) => dispatch(actions.setTeamProjectName({ value: e.target.value }))}
                error={name.error}
            />
            <DefaultCustomization />
            {isOnboardingTeamPage && (
                <div className='flex flex-col items-center gap-[16px]'>
                    <Button
                        className="w-[390px] h-[56px]"
                        onClick={handleSubmit}
                        disabled={!name.value || isLoading}
                        disabledClassName="bg-secondary"
                    >
                        Create team
                    </Button>
                    <Link className="leading-[24px] text-base font-normal text-black" to="#" onClick={onBack}>
                        Back
                    </Link>
                </div>
            )}
        </div>
    )
}
