'use client';
import clsx from 'clsx';
import React from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Button from 'app/components/Button';
import { PLANS } from 'app/containers/OnBoarding/types';

const Filters = () => { 
    const [searchParams] = useSearchParams();
    const plan = searchParams.get('plan') || PLANS.MONTHLY;
    const pathname = useLocation()?.pathname;
    const navigate = useNavigate();

    const handleClick = (plan:PLANS) => {
        navigate(`${pathname}?plan=${plan}`, {
            replace: true,
        });
    }


    return (
        <div className="flex flex-row items-center justify-center">
            <div className="flex flex-row items-center justify-center shadow-[2px_2px_6px_0px_#EDEFF2B2] w-fit">
                <Link to={`${pathname}?plan=${PLANS.MONTHLY}`}>
                    <Button 
                        className={clsx("text-sm nowrap !py-0 font-normal rounded-none uppercase bg-white px-4 h-[42px] focus:outline-none", {"!text-primary font-semibold border-b-[2px] border-primary": plan === PLANS.MONTHLY, "text-secondary": plan !== PLANS.MONTHLY})} 
                        onClick={()=>handleClick(PLANS.MONTHLY)}
                    >
                        MONTHLY
                    </Button>
                </Link>
                <Link to={`${pathname}?plan=${PLANS.YEARLY}`}>
                    <Button 
                        className={clsx("text-sm nowrap !py-0 font-normal rounded-none uppercase bg-white px-4 h-[42px] focus:outline-none", {"!text-primary font-semibold border-b-[2px] border-primary": plan === PLANS.YEARLY, "text-secondary": plan !== PLANS.YEARLY})} 
                        onClick={()=>handleClick(PLANS.YEARLY)}
                    >
                        YEARLY
                        <span className="text-[#75C75D] uppercase font-semibold"> save 18%</span>
                    </Button>
                </Link>
            </div>
        </div>
    );
}

export default Filters;
