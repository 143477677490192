import React from 'react';
import Card from '../Card';
import { PLAN_TYPE } from 'app/containers/OnBoarding/types';
import { PRICES } from '../Monthly';

const Yearly = ({
    onClick
}:{
    onClick: (plan: PLAN_TYPE) => void;
}) => {
    return (
        <>
            {
                PRICES.map((item) => (
                    <Card key={item?.title} item={item} onClick={onClick} />
                ))
            }
        </>
    );
}

export default Yearly;
