import { UserActivity } from "./containers/Features/containers/User/types";

export enum PLAN_TYPE {
  FREE = "free",
  BUSINESS = "business",
  PROFESSIONAL = "professional",
}

export interface FormElement {
  value: any;
  error: string;
}

export interface FormError {
  name: string;
  error: string;
}

export interface AuthorizedAccount {
  email: string;
  password: string;
}

export interface AppState {
  isAuthenticated: boolean;
  authorizedAccounts: AuthorizedAccount[];
  user: User | null;
  loading: boolean;
  error: string | null;
  drawerOpen: boolean;
  drawerPayload?: any;
  teamForm: TeamForm;
  teamFormActiveIndex: number;
}

export type TeamForm = {
  name: FormElement;
  project: {
    name: FormElement;
    defaultCustomization: {
      agileDevelopmentAndSprints: boolean;
      epicsAndStories: boolean;
      releaseManagement: boolean;
    };
  };
  members: TeamMemberType[];
  plan: PLAN_TYPE;
  coupon: FormElement;
  isLoading: boolean;
};

export interface User {
  email: string;
  name?: string;
  companySize?: string;
  referalSource?: string;
  usedTools?: string[];
  team?: Team;
  initials: string;
  role: string;
  status: string;
  lastLogin: string;
  localTime: string;
  location: string;
  spokenLanguages: string[];
  creationDate: string;
  mobileNumber: string;
  members: TeamMember[];
}

export interface Team {
  name: string;
  members: TeamMember[];
  plan: PLAN_TYPE;
}

export interface TeamMember {
  status: string;
  email: string;
  localTime: string;
  name: string;
  role: string;
  id: string;
  team: string;
  lastLogin: string;
  location: string;
  spokenLanguages: string;
  mobileNumber: string;
  creationDate: string;
}

export type Option = {
  label: string;
  value: string;
  description?: string;
  icon?: React.ReactNode;
};

export type TeamMemberType = {
  name: FormElement;
  role: FormElement;
};

export type ContainerState = AppState;
