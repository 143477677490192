import React from "react";

interface UnCheckedIconProps {
    size?: number;
    color?: string;
}

export const UnCheckedIcon = ({ size = 18, color = "#79808A" }: UnCheckedIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size + 1} viewBox="0 0 18 19" fill="none">
            <circle cx="9" cy="9.07324" r="8.25" stroke={color} strokeWidth="1.5"/>
        </svg>
    )
}