import React from 'react'
import Layout from '../../components/Layout'
import { useParams } from 'react-router-dom'
import { useInjectReducer } from 'redux-injectors'
import { ticketGroupsSlice, ticketGroupsReducer } from './redux/slice'
import Tickets from './components/Tickets'

export default function BoardView() {
    useInjectReducer({ key: ticketGroupsSlice, reducer: ticketGroupsReducer });
    const params = useParams()
    const id = params?.id
    return (
        <Layout>
            <Tickets id={id!} />
        </Layout>
    )
}
                        