import React from 'react'

interface BoardsViewIconProps {
    color: string
    size: string
}

export default function BoardsViewIcon({ color, size }: BoardsViewIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M15.9537 5.86154H4.04892C3.39192 5.86154 2.76123 6.11447 2.29574 6.56564C1.83014 7.01691 1.56797 7.62964 1.56797 8.26923V11.7308C1.56797 12.3704 1.83014 12.9831 2.29574 13.4344C2.76123 13.8855 3.39192 14.1385 4.04892 14.1385H15.9537C16.6107 14.1385 17.2414 13.8855 17.7069 13.4344C18.1725 12.9831 18.4346 12.3704 18.4346 11.7308V8.26923C18.4346 7.62964 18.1725 7.01691 17.7069 6.56564C17.2414 6.11447 16.6107 5.86154 15.9537 5.86154ZM3.27672 7.52515C3.48101 7.32715 3.75872 7.21538 4.04892 7.21538H15.9537C16.2439 7.21538 16.5216 7.32715 16.7259 7.52515C16.9301 7.72304 17.0442 7.99076 17.0442 8.26923V11.7308C17.0442 12.0092 16.9301 12.277 16.7259 12.4749C16.5216 12.6728 16.2439 12.7846 15.9537 12.7846H4.04892C3.75872 12.7846 3.48101 12.6728 3.27672 12.4749C3.07255 12.277 2.95845 12.0092 2.95845 11.7308V8.26923C2.95845 7.99076 3.07255 7.72304 3.27672 7.52515ZM1.77271 2.59717C1.642 2.72386 1.56797 2.89637 1.56797 3.07692C1.56797 3.25748 1.642 3.42999 1.77271 3.55668C1.90332 3.68326 2.07981 3.75385 2.26321 3.75385H17.7394C17.9228 3.75385 18.0993 3.68326 18.2299 3.55668C18.3606 3.42999 18.4346 3.25748 18.4346 3.07692C18.4346 2.89637 18.3606 2.72386 18.2299 2.59717C18.0993 2.47058 17.9228 2.4 17.7394 2.4H2.26321C2.07981 2.4 1.90332 2.47058 1.77271 2.59717ZM1.77271 16.4433C1.642 16.57 1.56797 16.7425 1.56797 16.9231C1.56797 17.1036 1.642 17.2761 1.77271 17.4028C1.90332 17.5294 2.07981 17.6 2.26321 17.6H17.7394C17.9228 17.6 18.0993 17.5294 18.2299 17.4028C18.3606 17.2761 18.4346 17.1036 18.4346 16.9231C18.4346 16.7425 18.3606 16.57 18.2299 16.4433C18.0993 16.3167 17.9228 16.2462 17.7394 16.2462H2.26321C2.07981 16.2462 1.90332 16.3167 1.77271 16.4433Z" fill={color} stroke={color} strokeWidth="0.2"/>
        </svg>
    )
}
