import React from 'react'

interface EditIconProps {
    size: number;
    color: string;
}       

export default function EditIcon({ size = 20, color = '#79808A' }: EditIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M12.4948 4.99714L14.9948 7.49714M10.8281 16.6638H17.4948M4.16146 13.3305L3.32812 16.6638L6.66146 15.8305L16.3165 6.17548C16.6289 5.86293 16.8044 5.43908 16.8044 4.99714C16.8044 4.5552 16.6289 4.13136 16.3165 3.81881L16.1731 3.67548C15.8606 3.36303 15.4367 3.1875 14.9948 3.1875C14.5529 3.1875 14.129 3.36303 13.8165 3.67548L4.16146 13.3305Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}