import React from 'react';

interface TrashIconProps {      
    size?: number;
    color?: string;
}

export default function TrashIcon({ size = 20, color = '#EB5757' }: TrashIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M6.24143 17C5.85857 17 5.532 16.8603 5.26171 16.581C4.992 16.3016 4.85714 15.9634 4.85714 15.5663V4.57134H4V3.68358H7.42857V3H12.5714V3.68358H16V4.57134H15.1429V15.5663C15.1429 15.9746 15.0109 16.3155 14.7469 16.589C14.4823 16.863 14.1529 17 13.7586 17H6.24143ZM14.2857 4.57134H5.71429V15.5663C5.71429 15.7255 5.76371 15.8563 5.86257 15.9587C5.96143 16.061 6.08771 16.1122 6.24143 16.1122H13.7586C13.89 16.1122 14.0109 16.0554 14.1211 15.9418C14.2309 15.8276 14.2857 15.7024 14.2857 15.5663V4.57134ZM8.12114 14.3367H8.97829V6.34686H8.12114V14.3367ZM11.0217 14.3367H11.8789V6.34686H11.0217V14.3367Z" fill={color}/>
            <path d="M6.24143 17C5.85857 17 5.532 16.8603 5.26171 16.581C4.992 16.3016 4.85714 15.9634 4.85714 15.5663V4.57134H4V3.68358H7.42857V3H12.5714V3.68358H16V4.57134H15.1429V15.5663C15.1429 15.9746 15.0109 16.3155 14.7469 16.589C14.4823 16.863 14.1529 17 13.7586 17H6.24143ZM14.2857 4.57134H5.71429V15.5663C5.71429 15.7255 5.76371 15.8563 5.86257 15.9587C5.96143 16.061 6.08771 16.1122 6.24143 16.1122H13.7586C13.89 16.1122 14.0109 16.0554 14.1211 15.9418C14.2309 15.8276 14.2857 15.7024 14.2857 15.5663V4.57134ZM8.12114 14.3367H8.97829V6.34686H8.12114V14.3367ZM11.0217 14.3367H11.8789V6.34686H11.0217V14.3367Z" stroke={color}/>
        </svg>
    )
}
