import React from 'react'

export default function OtherReleaseIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <path d="M11.7701 0.626969C11.5313 0.752059 11.433 0.925796 11.433 1.21767C11.433 1.41921 11.5734 1.58599 12.6268 2.63536C13.2869 3.28861 13.8206 3.84456 13.8206 3.87236C13.8206 3.89321 13.7714 3.90016 13.7152 3.87931C13.6591 3.85846 13.1745 3.87236 12.6338 3.91406C9.61419 4.15034 7.87265 5.3387 7.24766 7.61117C7.15637 7.92389 7.12126 8.32001 7.12126 8.95936C7.11424 10.5369 7.33193 11.1484 7.91478 11.1484C8.42741 11.1484 8.70129 10.7523 8.56084 10.2103C8.51871 10.0435 8.46253 9.62651 8.44146 9.29293C8.30803 7.32624 9.3333 5.9572 11.3276 5.44294C11.8333 5.31785 13.7784 5.15801 13.6801 5.2553C13.6591 5.27615 13.2026 5.67227 12.6619 6.14483C11.4821 7.15945 11.433 7.21505 11.433 7.47913C11.433 7.90999 11.6788 8.15322 12.1071 8.16017C12.388 8.16017 12.4723 8.09763 14.4456 6.37416L15.7627 5.22697C16.1589 4.88188 16.1788 4.27302 15.806 3.90277L14.5439 2.64926C13.4695 1.58599 12.5074 0.668666 12.4021 0.61307C12.1633 0.48798 12.0088 0.494929 11.7701 0.626969Z" fill="#FF7979"/>
            <path d="M2.19561 2.46377C1.48324 2.63986 0.986985 3.00805 0.80289 3.49631C0.74686 3.64839 0.714844 5.47334 0.714844 9.02719V14.3259L0.922952 14.7422C1.03501 14.9663 1.30715 15.3105 1.52326 15.5106C2.22763 16.1429 1.90746 16.1189 8.67098 16.1189C15.4345 16.1189 15.0583 16.1509 15.5866 15.5106C16.0668 14.9343 16.0828 14.7662 16.0828 10.9962C16.0828 7.67448 16.0748 7.57043 15.9228 7.37032C15.5946 6.95411 14.8182 7.05016 14.6421 7.5224C14.5941 7.64246 14.562 9.10723 14.562 11.0042C14.562 13.1173 14.53 14.342 14.482 14.446C14.402 14.5981 14.2499 14.5981 8.59094 14.5981H2.77992L2.50778 14.3259L2.23563 14.0538V9.01118V3.97656L2.60383 3.92053C2.81193 3.89652 4.4688 3.8725 6.28574 3.8725H9.58346L9.79157 3.62437C10.1437 3.21616 9.99967 2.60785 9.51142 2.43976C9.13522 2.31169 2.73189 2.3277 2.19561 2.46377Z" fill="#FF7979"/>
        </svg>
    )
}
