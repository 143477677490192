import React from "react";

interface CheckedIconProps {
    size?: number;
    color?: string;
}

export const CheckedIcon = ({ size = 18, color = "#79808A" }: CheckedIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 19 19" fill="none">
            <circle cx="9.5" cy="9.07324" r="8.25" fill="#DFECFF" stroke={color} strokeWidth="1.5"/>
            <circle cx="9.5" cy="9.07324" r="3.25" fill="white" stroke={color} strokeWidth="1.5"/>
        </svg>
    )
}