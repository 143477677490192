import React from 'react'
import { getInitials } from 'utils/helpers'

interface AvatarProps {
    readonly name: string
    readonly color?: string
    readonly className?: string
    readonly circleClassName?: string
    readonly nameClassName?: string
    readonly statusClassName?: string
    readonly detailsClassName?: string
    readonly caption?: string
    readonly captionClassName?: string 
}

export default function Avatar({ name, color="#8CCDEC", className, circleClassName, nameClassName, statusClassName, detailsClassName, caption, captionClassName }: AvatarProps) {
    return (
        <div className={`flex flex-row items-center gap-[6px] cursor-pointer ${className}`}>
            <div className='flex flex-row items-center relative'>
                <div style={{ backgroundColor: color }} className={`text-white text-[10px] size-[20px] leading-[21px] flex items-center justify-center rounded-full ${circleClassName}`}>
                    {getInitials(name)}
                </div>
                <div className={`size-[5px] bg-[#6FCF97] rounded-full absolute bottom-[0px] right-[0px] ${statusClassName}`} />
            </div>
            <div className={`flex flex-col ${detailsClassName}`}>
                <p className={`text-sm leading-[21px] font-normal text-[#333E4D] ${nameClassName}`}>{name}</p>
                {caption && <p className={`text-xs leading-[18px] font-normal text-[#79808A] ${captionClassName}`}>{caption}</p>}
            </div>
        </div>
    )
}
