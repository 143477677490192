import Modal from "app/components/Modal";
import React from "react";
import { CARDS } from "./PaymentDialog";
import Input from "app/components/Input";

interface AddCardModalProps {
    onClose: () => void;
    onSubmit: () => void;
}

export default function AddCardModal({ onClose, onSubmit }: AddCardModalProps) {
    return (
        <Modal 
            onClose={onClose} 
            title="Add card" 
            // contentClassName="!p-0"
            submitText="Add"
            onSubmit={onSubmit}
        >
            <div className="flex flex-col gap-[16px]">
                <div className="flex flex-row items-center gap-[10px]">
                    {
                        CARDS.map((card) => (
                            <div className="w-[78.2px] h-[37px] flex items-center justify-center rounded-[4px]" style={{ backgroundColor: card.bg }}>
                                <img src={card.img} alt={card.alt} width={card.width} height={card.height} />
                            </div>
                        ))
                    }
                </div>
                <div className="grid grid-cols-[2fr_1fr] gap-[16px]">
                    <Input 
                        label="Card number"
                        id="card-number"
                        placeholder="XXXX XXXX XXXX XXXX"
                    />
                    <Input 
                        label="Expiration date"
                        id="expiration-date"
                        placeholder="MM/YY"
                    />
                    <Input 
                        label="Cardholder name"
                        id="cardholder-name"
                        placeholder="JOHN SNOW"
                    />
                    <Input 
                        label="CVV"
                        id="cvv"
                        placeholder="XXX"
                    />
                </div>
            </div>
        </Modal>
    )
}