import React from "react";

interface ChildIconProps {
    color?: string;
    size?: number;
}

const ChildIcon = ({ color = "#F2C94C", size = 18 }: ChildIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18" fill="none">
            <path d="M11.25 3.75C11.25 4.34674 11.0129 4.91903 10.591 5.34099C10.169 5.76295 9.59674 6 9 6C8.40326 6 7.83097 5.76295 7.40901 5.34099C6.98705 4.91903 6.75 4.34674 6.75 3.75C6.75 3.15326 6.98705 2.58097 7.40901 2.15901C7.83097 1.73705 8.40326 1.5 9 1.5C9.59674 1.5 10.169 1.73705 10.591 2.15901C11.0129 2.58097 11.25 3.15326 11.25 3.75ZM6 14.25C6 14.8467 5.76295 15.419 5.34099 15.841C4.91903 16.2629 4.34674 16.5 3.75 16.5C3.15326 16.5 2.58097 16.2629 2.15901 15.841C1.73705 15.419 1.5 14.8467 1.5 14.25C1.5 13.6533 1.73705 13.081 2.15901 12.659C2.58097 12.2371 3.15326 12 3.75 12C4.34674 12 4.91903 12.2371 5.34099 12.659C5.76295 13.081 6 13.6533 6 14.25ZM16.5 14.25C16.5 14.8467 16.2629 15.419 15.841 15.841C15.419 16.2629 14.8467 16.5 14.25 16.5C13.6533 16.5 13.081 16.2629 12.659 15.841C12.2371 15.419 12 14.8467 12 14.25C12 13.6533 12.2371 13.081 12.659 12.659C13.081 12.2371 13.6533 12 14.25 12C14.8467 12 15.419 12.2371 15.841 12.659C16.2629 13.081 16.5 13.6533 16.5 14.25Z" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.25 3.75C11.25 4.34674 11.0129 4.91903 10.591 5.34099C10.169 5.76295 9.59674 6 9 6C8.40326 6 7.83097 5.76295 7.40901 5.34099C6.98705 4.91903 6.75 4.34674 6.75 3.75C6.75 3.15326 6.98705 2.58097 7.40901 2.15901C7.83097 1.73705 8.40326 1.5 9 1.5C9.59674 1.5 10.169 1.73705 10.591 2.15901C11.0129 2.58097 11.25 3.15326 11.25 3.75ZM6 14.25C6 14.8467 5.76295 15.419 5.34099 15.841C4.91903 16.2629 4.34674 16.5 3.75 16.5C3.15326 16.5 2.58097 16.2629 2.15901 15.841C1.73705 15.419 1.5 14.8467 1.5 14.25C1.5 13.6533 1.73705 13.081 2.15901 12.659C2.58097 12.2371 3.15326 12 3.75 12C4.34674 12 4.91903 12.2371 5.34099 12.659C5.76295 13.081 6 13.6533 6 14.25ZM16.5 14.25C16.5 14.8467 16.2629 15.419 15.841 15.841C15.419 16.2629 14.8467 16.5 14.25 16.5C13.6533 16.5 13.081 16.2629 12.659 15.841C12.2371 15.419 12 14.8467 12 14.25C12 13.6533 12.2371 13.081 12.659 12.659C13.081 12.2371 13.6533 12 14.25 12C14.8467 12 15.419 12.2371 15.841 12.659C16.2629 13.081 16.5 13.6533 16.5 14.25Z" fill={color} stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <ellipse cx="9.00065" cy="3.83073" rx="1.66667" ry="1.66667" fill="white"/>
            <path d="M9.00195 6.32812V8.99609M9.00195 8.99609H6.37695C5.13945 8.99609 4.5207 8.99609 4.1367 9.32609C3.75195 9.65459 3.75195 10.1848 3.75195 11.2461L3.75195 11.9961M9.00195 8.99609H11.627C12.8645 8.99609 13.4832 8.99609 13.8672 9.32609C14.252 9.65459 14.252 10.1848 14.252 11.2461V11.9961" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default ChildIcon;