import React from "react";

interface CloseIconProps {
    size?: string;
    color?: string;
}

export const CloseIcon = ({ size = '16', color = 'white' }: CloseIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
            <path d="M12.1962 3.80291C12.1346 3.74111 12.0613 3.69208 11.9807 3.65862C11.9 3.62517 11.8136 3.60795 11.7262 3.60795C11.6389 3.60795 11.5525 3.62517 11.4718 3.65862C11.3912 3.69208 11.3179 3.74111 11.2562 3.80291L7.99624 7.05624L4.73624 3.79624C4.67452 3.73452 4.60125 3.68556 4.52061 3.65216C4.43996 3.61875 4.35353 3.60156 4.26624 3.60156C4.17896 3.60156 4.09252 3.61875 4.01188 3.65216C3.93124 3.68556 3.85796 3.73452 3.79624 3.79624C3.73452 3.85796 3.68556 3.93124 3.65216 4.01188C3.61875 4.09252 3.60156 4.17896 3.60156 4.26624C3.60156 4.35353 3.61875 4.43996 3.65216 4.52061C3.68556 4.60125 3.73452 4.67452 3.79624 4.73624L7.05624 7.99624L3.79624 11.2562C3.73452 11.318 3.68556 11.3912 3.65216 11.4719C3.61875 11.5525 3.60156 11.639 3.60156 11.7262C3.60156 11.8135 3.61875 11.9 3.65216 11.9806C3.68556 12.0612 3.73452 12.1345 3.79624 12.1962C3.85796 12.258 3.93124 12.3069 4.01188 12.3403C4.09252 12.3737 4.17896 12.3909 4.26624 12.3909C4.35353 12.3909 4.43996 12.3737 4.52061 12.3403C4.60125 12.3069 4.67452 12.258 4.73624 12.1962L7.99624 8.93624L11.2562 12.1962C11.318 12.258 11.3912 12.3069 11.4719 12.3403C11.5525 12.3737 11.639 12.3909 11.7262 12.3909C11.8135 12.3909 11.9 12.3737 11.9806 12.3403C12.0612 12.3069 12.1345 12.258 12.1962 12.1962C12.258 12.1345 12.3069 12.0612 12.3403 11.9806C12.3737 11.9 12.3909 11.8135 12.3909 11.7262C12.3909 11.639 12.3737 11.5525 12.3403 11.4719C12.3069 11.3912 12.258 11.318 12.1962 11.2562L8.93624 7.99624L12.1962 4.73624C12.4496 4.48291 12.4496 4.05624 12.1962 3.80291Z" fill={color}/>
        </svg>
    )
}
