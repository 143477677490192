/**
 * Boards selectors
 */

import { RootState } from "types";

import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// TODO: Add an explanation for this
const selectDomain = (state: RootState) =>
  state?.OnBoardingState || initialState;

export const selectProfileForm = createSelector(
  [selectDomain],
  (OnBoardingState) => OnBoardingState.profileForm
);

export const selectSetupForm = createSelector(
  [selectDomain],
  (OnBoardingState) => OnBoardingState.setupForm
);
