import React from 'react'

interface DropupIconProps {
    readonly size?: number
    readonly color?: string
}

export default function DropupIcon({ size=20, color='#79808A' }: DropupIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M14.1693 11.6667L10.0026 7.5L5.83594 11.6667" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}