import React from 'react';

interface HeartIconProps {
    size?: number;
    color?: string;
}

const HeartIcon = ({ 
    size = 16,
    color = '#fff'
 }: HeartIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
            <path d="M12.1663 8.66431L8.83147 11.9997C8.61037 12.2208 8.31054 12.3449 7.99791 12.3449C7.68527 12.3449 7.38544 12.2208 7.16434 11.9997L3.82949 8.6649C3.55382 8.39163 3.33484 8.0666 3.18512 7.70848C3.0354 7.35035 2.95789 6.96618 2.95704 6.57802C2.95619 6.18986 3.03202 5.80535 3.18017 5.44657C3.32832 5.08779 3.54587 4.76181 3.82035 4.48734C4.09482 4.21287 4.4208 3.99531 4.77958 3.84716C5.13836 3.69901 5.52286 3.62318 5.91103 3.62403C6.29919 3.62488 6.68336 3.70239 7.04148 3.85211C7.39961 4.00183 7.72464 4.22081 7.99791 4.49649C8.55297 3.95524 9.29892 3.65447 10.0742 3.65933C10.8494 3.66419 11.5915 3.97428 12.1398 4.52244C12.688 5.0706 12.9982 5.81268 13.0032 6.58793C13.0081 7.36318 12.7075 8.10917 12.1663 8.66431Z" stroke={color} strokeWidth="0.884262" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default HeartIcon;