import React from "react";

interface DuplicateIconProps {
    color?: string;
    size?: number;
}

export const DuplicateIcon = ({ color = "#D16DEA", size = 20 }: DuplicateIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M6.66732 5.83333L6.66732 12.5C6.66732 12.942 6.84291 13.366 7.15547 13.6785C7.46803 13.9911 7.89196 14.1667 8.33398 14.1667L13.334 14.1667M6.66732 5.83333V4.16667C6.66732 3.72464 6.84291 3.30072 7.15547 2.98816C7.46803 2.67559 7.89196 2.5 8.33398 2.5L12.1557 2.5C12.3766 2.50005 12.5886 2.58788 12.7448 2.74417L16.4232 6.4225C16.5794 6.57874 16.6673 6.79067 16.6673 7.01167V12.5C16.6673 12.942 16.4917 13.366 16.1792 13.6785C15.8666 13.9911 15.4427 14.1667 15.0007 14.1667H13.334M6.66732 5.83333H5.00065C4.55862 5.83333 4.1347 6.00893 3.82214 6.32149C3.50958 6.63405 3.33398 7.05797 3.33398 7.5L3.33398 15.8333C3.33398 16.2754 3.50958 16.6993 3.82214 17.0118C4.1347 17.3244 4.55862 17.5 5.00065 17.5H11.6673C12.1093 17.5 12.5333 17.3244 12.8458 17.0118C13.1584 16.6993 13.334 16.2754 13.334 15.8333V14.1667" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
};