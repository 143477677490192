import React from "react";

interface BlockedIconProps {
    readonly size?: number;
    readonly color?: string;
}

const BlockedIcon = ({ size = 20, color = "#FA3F77" }: BlockedIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M17.5841 11.5061C17.3624 12.6205 16.8989 13.6675 16.2318 14.5757C17.2532 13.1622 17.7707 11.4402 17.6907 9.68779C17.6026 7.75838 16.7967 5.93153 15.4309 4.56581C14.0652 3.20009 12.2384 2.3941 10.3089 2.30603C8.55539 2.22599 6.83219 2.74422 5.41821 3.76698C6.73791 2.79661 8.34117 2.26406 9.99935 2.26406C11.5289 2.26406 13.024 2.71761 14.2958 3.56736C15.5675 4.41711 16.5587 5.6249 17.144 7.03798C17.7293 8.45106 17.8825 10.006 17.5841 11.5061Z" stroke={color} strokeWidth="1.2"/>
        </svg>
    )
}

export default BlockedIcon;